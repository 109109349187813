import { useEffect, useState } from 'react';

import { debounce } from 'lodash';

export const widthBreakpoints = {
  mobile_s: 325,
  mobile_m: 375,
  mobile_l: 440,
  mobile_xl: 575,
  tablet_s: 768,
  tablet_m: 820,
  tablet_l: 1024,
  desktop_s: 1280,
  desktop_m: 1440,
  desktop_l: 1680,
  desktop_xl: 1920,
  desktop_wide: 2560,
};

const useWindowSize = () => {
  const [currentSize, setCurrentSize] = useState<number>(768);

  useEffect(() => {
    const handleResize = () => setCurrentSize(window.innerWidth);
    const debouncedFunc = debounce(handleResize, 1000);
    window.addEventListener('resize', debouncedFunc);
    handleResize();

    return () => window.removeEventListener('resize', debouncedFunc);
  }, []);

  return { currentSize };
};

export default useWindowSize;
