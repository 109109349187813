import React, { ReactNode } from 'react';

import cn from 'classnames';

import styles from './Typography.module.scss';

interface HeadingProps {
  children: ReactNode[] | ReactNode | string;
  className?: string;
}

type TitleProps = {
  level?: 1 | 2 | 3 | 4;
  children: ReactNode | ReactNode[] | string;
  className?: string;
};

type ParagrapProps = {
  level?: 1 | 2;
  children: ReactNode | ReactNode[] | string;
  className?: string;
  weight?: 400 | 500 | 600 | 700;
};

const Title = (props: TitleProps) => {
  const { level = 3, children, className } = props;
  const Elem = h[`h${level}`];
  return <Elem className={className}>{children}</Elem>;
};

const Paragraph = (props: ParagrapProps) => {
  const { children, level = 1, className, weight = 700 } = props;
  return (
    <p
      className={cn(styles.p, className, {
        [styles.p1]: level === 1,
        [styles.p2]: level === 2,
      })}
      style={{ fontWeight: weight }}
    >
      {children}
    </p>
  );
};

const h1 = ({ children, className }: HeadingProps) => {
  return <h1 className={cn(styles.title, styles.h1, className)}>{children}</h1>;
};

const h2 = ({ children, className }: HeadingProps) => {
  return <h2 className={cn(styles.title, styles.h2, className)}>{children}</h2>;
};

const h3 = ({ children, className }: HeadingProps) => {
  return <h3 className={cn(styles.title, styles.h3, className)}>{children}</h3>;
};

const h4 = ({ children, className }: HeadingProps) => {
  return <h4 className={cn(styles.title, styles.h4, className)}>{children}</h4>;
};

const h = {
  h1,
  h2,
  h3,
  h4,
};

const Typography = {
  Title,
  Paragraph,
};

export default Typography;
