import { ButtonHTMLAttributes, MouseEvent } from 'react';

import cn from 'classnames';

import Spinner from '@/src/components/Spinner';

import { Typography } from '../Typography';

import styles from './Button.module.scss';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  theme?: 'gray' | 'blue';
  weight?: 400 | 500 | 600 | 700;
  className?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
}

const Button = ({
  text,
  weight,
  className,
  onClick,
  disabled,
  loading = false,
  theme,
  ...params
}: Props) => (
  <button
    {...params}
    className={cn(styles.button, className, styles[`button__${theme}`])}
    onClick={onClick}
    disabled={loading || disabled}
  >
    {loading && (
      <span className={styles.button__loader}>
        <Spinner size={25} />
      </span>
    )}
    <Typography.Paragraph
      level={1}
      weight={weight}
      className={cn({ [styles['button__text--loading']]: loading })}
    >
      {text}
    </Typography.Paragraph>
  </button>
);

export default Button;
