interface NavigationItem {
  href?: string;
  mobile?: boolean;
  title: string;
  childs?: { href?: string; title: string; locale?: 'es' | 'en' }[];
}

export const navigation: NavigationItem[] = [
  {
    title: 'games',
    href: '/games',
  },
  {
    title: 'slot_machines',
    childs: [
      {
        href: '/slot-machines/nevada',
        title: 'nevada',
      },
      {
        href: '/slot-machines/neostar',
        title: 'neostar',
      },
      {
        href: '/slot-machines/riogrande',
        title: 'riogrande',
      },
      {
        href: '/slot-machines/compare',
        title: 'compareModels',
      },
    ],
  },
  {
    title: 'partners',
    href: '/partners',
  },
  {
    title: 'company',
    childs: [
      {
        href: '/about',
        title: 'about',
      },
      {
        href: '/career',
        title: 'career',
      },
    ],
  },
  {
    href: '/news',
    title: 'news',
  },
  {
    title: 'contact',
    href: '/contact',
  },
  // {
  //   title: 'language',
  //   mobile: true,
  //   childs: [
  //     {
  //       title: 'inEnglish',
  //       locale: 'en',
  //     },
  //     {
  //       title: 'inSpanish',
  //       locale: 'es',
  //     },
  //   ],
  // },
];

export const footer_navigation: { href: string; title: string }[] = [
  {
    title: 'navigation.games',
    href: '/games',
  },
  {
    title: 'navigation.slot_machines',
    href: '/slot-machines/compare',
  },
  {
    title: 'navigation.partners',
    href: '/partners',
  },
  {
    title: 'navigation.news',
    href: '/news',
  },
  {
    title: 'navigation.career',
    href: '/career',
  },
  {
    title: 'navigation.company',
    href: '/about',
  },
  {
    title: 'navigation.contact',
    href: '/contact',
  },
  {
    title: 'navigation.client_area',
    href: '/profile',
  },
];

export const footer_privacy = [
  {
    title: 'Privacy Statement',
    href: '/docs/privacy-policy.pdf',
  },
  {
    title: 'Terms Of Use',
    href: '/',
  },
  {
    title: 'Regulatory',
    href: '/',
  },
];

export const FACEBOOK_LINK = 'https://www.facebook.com/belatragames?ref=hl';
export const TWITTER_LINK = 'https://twitter.com/BelatraGames';
export const LINKEDIN_LINK = 'https://www.linkedin.com/company/3301736/';
export const INSTAGRAM_LINK = 'https://www.instagram.com/belatragames?igsh=bTF4bHV1bGtndHRo ';
export const YOUTUBE_LINK = 'https://www.youtube.com/channel/UCbcjG51t_vzGvrgCxR24y7w';
