import cn from 'classnames';

import styles from './Container.module.scss';

type Props = {
  children: JSX.Element | JSX.Element[];
  className?: string;
};
const Container = ({ children, className }: Props) => {
  return <div className={cn(styles.container, className)}>{children}</div>;
};

export default Container;
