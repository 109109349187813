'use client';
import { useCallback } from 'react';

import cn from 'classnames';
import { useTranslations } from 'next-intl';

import { Link } from '@/src/i18n/routing';

import { NavItemType } from '../NavItem';

import styles from './NavDroplist.module.scss';

const DropList = ({
  items,
  path,
  setActive,
}: {
  items: (Omit<NavItemType, 'childs'> & { onClick?: () => void })[];
  path: string;
  setActive?: (b: boolean) => void;
}) => {
  const t = useTranslations('navigation');
  const isActive = items.find(item =>
    item?.href && item?.href !== '/' ? path.includes(item.href) : false,
  );

  const defaultSetActive = () => setActive?.(!!isActive);
  useCallback(defaultSetActive, [])();

  return (
    <ul className={styles.nav_droplist}>
      {items?.map(({ title, href, onClick }) =>
        href ? (
          <li
            key={title}
            onClick={onClick}
            className={cn(styles.nav_droplist__item, {
              [styles.nav_droplist__item__active]: href && href !== '/' && path.includes(href),
            })}
          >
            <Link href={href} key={title}>
              {t(title)}
            </Link>
          </li>
        ) : (
          <li
            className={cn({
              [styles.nav_droplist__item__active]: href && href !== '/' && path.includes(href),
            })}
            key={title}
            onClick={onClick}
          >
            {t(title)}
          </li>
        ),
      )}
    </ul>
  );
};

export default DropList;
