import { SUBSCRIBE_TO_UPDATES_ENDPOINT } from '@/src/api/endpoints/server';
import { postRequest } from '@/src/api/index';
import { DataResponse } from '@/src/domain/common/DataResponse';
import { ServiceDefaultParams } from '@/src/domain/common/ServiceDefaultParams';
import { getXLangHeader } from '@/src/utils/getXLangHeader';
import toErrorWithMessage from '@/src/utils/toErrorWithMessage';

export interface PostMeetingFormParams {
  email: string;
}

const postSubscribeToUpdates = async ({
  locale,
  email,
}: ServiceDefaultParams<PostMeetingFormParams>): Promise<DataResponse<unknown>> => {
  try {
    const payload = { email };
    const response = await postRequest({
      url: SUBSCRIBE_TO_UPDATES_ENDPOINT,
      body: payload,
      headers: { ...getXLangHeader(locale) },
    });
    return { ok: true, data: response };
  } catch (e) {
    const message = toErrorWithMessage(e, true);
    throw Error(message);
  }
};

export default postSubscribeToUpdates;
