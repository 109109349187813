'use client';
import { MouseEvent } from 'react';

import cn from 'classnames';

import { TabItemType } from '@/src/domain/common/TabItemType';

import Button from '../Button';

import styles from './TabsButtonList.module.scss';

interface Props {
  data: TabItemType[];
  onChange: (id: string) => void;
  activeTabId?: string;
  className?: string;
}

const TabsButtonList = ({ data, className, activeTabId, onChange }: Props) => {
  const onChangeHandler = (e: MouseEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.getAttribute('data-id');

    if (id) {
      onChange(id);
    }
  };

  return (
    <ul className={cn(styles.categories_list, className)}>
      {data?.map(({ id, title }) => (
        <div
          key={id}
          className={cn(styles.button_wrapper, {
            [styles['button_wrapper--active']]: activeTabId === String(id),
          })}
        >
          <Button onClick={onChangeHandler} weight={400} theme="blue" text={title} data-id={id} />
        </div>
      ))}
    </ul>
  );
};

export default TabsButtonList;
