import { GameType } from '@/src/domain/games/game/GameType';

import { GameJsonType } from './game/GameJsonType';

const mapJsonToGame = (json: GameJsonType): GameType => ({
  id: json.id,
  title: json.title,
  slug: json.slug,
  image: json.preview,
  tag: json.tag,
  category: json?.category,
});

export default mapJsonToGame;
