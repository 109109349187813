'use client';

import cn from 'classnames';
import { useLocale, useTranslations } from 'next-intl';
import { usePathname } from 'next/navigation';
import Collapsible from 'react-collapsible';

import { Close, SearchIcon } from '@/assets/Icons';
import {
  LOGIN_APP_ENDPOINT,
  PROFILE_APP_ENDPOINT,
  REGISTRATION_APP_ENDPOINT,
  RESTORE_PASSWORD_APP_ENDPOINT,
} from '@/src/api/endpoints/app';
import { navigation } from '@/src/api/navigation';
import useWindowSize, { widthBreakpoints } from '@/src/hooks/useResize';
import { Link, LocaleEnum } from '@/src/i18n/routing';

import { ProfileTabsTypesEnum } from '../../../ProfilePage/components/ProfileTabsList';
import { Typography } from '../../../Typography';
import { NavItemType } from '../NavItem';

import styles from './BurgerMenu.module.scss';

interface Props {
  isActive: boolean;
  toggleMenu: () => void;
  toggleSearch: () => void;
  logout: () => void;
  isAuth: boolean;
}

const BurgerMenu = ({ isActive, toggleMenu, toggleSearch, logout, isAuth }: Props) => {
  const t = useTranslations('navigation');
  const { currentSize } = useWindowSize();
  const path = usePathname() ?? '';
  const locale = useLocale();

  const isMobile = currentSize < widthBreakpoints.mobile_xl;
  const isTablet = currentSize < widthBreakpoints.tablet_l;
  const getIsLinkActive = (href?: string) => {
    return href && href !== '/' ? path.includes(href) : false;
  };
  const getIsParentActive = (childs: Omit<NavItemType, 'childs'>[]) =>
    childs.find(item => (item?.href && item?.href !== '/' ? path.includes(item.href) : false));
  const isAccountLinkActive =
    path.includes(PROFILE_APP_ENDPOINT) ||
    path.includes(LOGIN_APP_ENDPOINT) ||
    path.includes(RESTORE_PASSWORD_APP_ENDPOINT) ||
    path.includes(REGISTRATION_APP_ENDPOINT);

  const openSearch = () => {
    toggleMenu();
    toggleSearch();
  };

  const onLogout = () => {
    logout();
    toggleMenu();
  };

  return (
    <div
      className={cn(styles.burger_menu, {
        [styles.burger_menu__open]: isActive,
        [styles.burger_menu__open__tablet]: isTablet && isActive,
        [styles.burger_menu__open__mobile]: isMobile && isActive,
      })}
    >
      <ul className={styles.burger_menu__actions}>
        <li className={styles.burger_menu_search} onClick={openSearch}>
          <SearchIcon />
        </li>
        <li className={styles.burger_menu__close} onClick={toggleMenu}>
          <Close />
        </li>
      </ul>
      <ul className={styles.burger_menu__nav}>
        {navigation.map(({ title, href, childs }) =>
          childs ? (
            <li key={title}>
              <Collapsible
                trigger={
                  <p
                    className={cn(styles.burger_menu__item_paragraph, {
                      [styles.burger_menu__item__active]: getIsParentActive(childs),
                    })}
                  >
                    {t(title)}
                  </p>
                }
                transitionTime={250}
              >
                <ul>
                  {childs.map(({ href, title, locale: childLocale }) => (
                    <li
                      key={title + href}
                      className={cn(styles.burger_menu__item_child, {
                        [styles.burger_menu__item__active]: childLocale
                          ? locale === childLocale
                          : getIsLinkActive(href),
                      })}
                    >
                      {href || childLocale ? (
                        <>
                          {/* Проверить */}
                          <Link
                            href={href ? href : path}
                            locale={(childLocale || locale) as LocaleEnum}
                            className={styles.burger_menu__item_link}
                            onClick={toggleMenu}
                          />
                          <Typography.Title level={3}>{t(title)}</Typography.Title>
                        </>
                      ) : (
                        <Typography.Title level={3}>{t(title)}</Typography.Title>
                      )}
                    </li>
                  ))}
                </ul>
              </Collapsible>
            </li>
          ) : (
            <li
              key={title}
              className={cn(styles.burger_menu__item, {
                [styles.burger_menu__item__active]: getIsLinkActive(href),
              })}
            >
              {href ? (
                <>
                  <Link
                    href={href}
                    className={styles.burger_menu__item_link}
                    onClick={toggleMenu}
                  />
                  <p className={styles.burger_menu__item_paragraph}>{t(title)}</p>
                </>
              ) : (
                <p className={styles.burger_menu__item_paragraph}>{t(title)}</p>
              )}
            </li>
          ),
        )}
        <li>
          <Link
            href={`${PROFILE_APP_ENDPOINT}/${ProfileTabsTypesEnum.GAMES}`}
            onClick={toggleMenu}
            className={cn(styles.burger_menu__item_paragraph, {
              [styles.burger_menu__item__active]: isAccountLinkActive,
            })}
          >
            {t('client_area')}
          </Link>
        </li>
        {isAuth ? (
          <li onClick={onLogout} tabIndex={0}>
            <Typography.Title
              className={cn(styles.burger_menu__item_child, styles.burger_menu__item_child__client)}
              level={3}
            >
              {t('logout')}
            </Typography.Title>
          </li>
        ) : (
          <></>
        )}
      </ul>
    </div>
  );
};

export default BurgerMenu;
