import { getRequest } from '@/src/api';
import {
  SEARCH_ARTICLES_ENDPOINT,
  SEARCH_COMMON_ENDPOINT,
  SEARCH_GAMES_ENDPOINT,
} from '@/src/api/endpoints/server';
import { GameJsonType } from '@/src/data/games/game/GameJsonType';
import mapJsonToGame from '@/src/data/games/mapJsonToGame';
import { mapJsonToNewsArticle } from '@/src/data/news/mapJsonToNewsArticle';
import { NewsArticleJson } from '@/src/data/news/NewsArticleJson';
import mapJsonToSearchResult from '@/src/data/search/mapJsonToSearchResult';
import { SearchResultJsonType } from '@/src/data/search/SearchResultJsonType';
import { DataResponse } from '@/src/domain/common/DataResponse';
import { JsonResponse } from '@/src/domain/common/JsonResponse';
import { SearchResultType } from '@/src/domain/search/SearchResultType';
import handleError from '@/src/utils/handleError';

export enum DefaultSearchCategory {
  ALL = 'all',
  GAMES = 'games',
}

const getSearchEndpoint = (category: string) => {
  switch (category) {
    case DefaultSearchCategory.ALL:
      return SEARCH_COMMON_ENDPOINT;
    case DefaultSearchCategory.GAMES:
      return SEARCH_GAMES_ENDPOINT;
    default:
      return SEARCH_ARTICLES_ENDPOINT;
  }
};

const getSearch = async ({
  value,
  category,
}: {
  value: string;
  category: string;
}): Promise<DataResponse<SearchResultType | undefined>> => {
  try {
    const { data: json }: JsonResponse<SearchResultJsonType | NewsArticleJson[] | GameJsonType[]> =
      await getRequest({
        url: getSearchEndpoint(category),
        query: {
          search: value,
          ...(category !== DefaultSearchCategory.ALL && category !== DefaultSearchCategory.GAMES
            ? { articles_category_id: category }
            : {}),
        },
      });

    let data: SearchResultType | null = null;

    if (Array.isArray(json)) {
      if (category === DefaultSearchCategory.GAMES) {
        data = {
          games: (json as GameJsonType[]).map(mapJsonToGame),
          articles: [],
        };
      } else {
        data = {
          games: [],
          articles: (json as NewsArticleJson[]).map(mapJsonToNewsArticle),
        };
      }
    } else {
      data = mapJsonToSearchResult(json);
    }

    return { ok: true, data };
  } catch (e) {
    handleError(e);
    return { ok: true, data: undefined };
  }
};

export default getSearch;
