import { createSharedPathnamesNavigation } from 'next-intl/navigation';
import { defineRouting } from 'next-intl/routing';

export enum LocaleEnum {
  EN = 'en',
  // ES = 'es',
}

export const localesArray = Object.values(LocaleEnum);
export const defaultLocale = LocaleEnum.EN;

export const routing = defineRouting({
  // A list of all locales that are supported
  locales: localesArray,
  defaultLocale: LocaleEnum.EN,
  localePrefix: 'always',
});

// Lightweight wrappers around Next.js' navigation APIs
// that will consider the routing configuration
export const { Link, redirect, usePathname, useRouter } = createSharedPathnamesNavigation(routing);
