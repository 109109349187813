import Image from 'next/image';

import { Logotype } from '@/assets/Icons';
import logo from '@/public/images/logo.gif';
import useWindowSize, { widthBreakpoints } from '@/src/hooks/useResize';
import { Link } from '@/src/i18n/routing';

import styles from './Logo.module.scss';

interface Props {
  isAnimated?: boolean;
  width?: number;
  height?: number;
}

const Logo = ({ isAnimated, width, height }: Props) => {
  const { currentSize } = useWindowSize();

  return (
    <div className={styles.logo}>
      <Link href="/">
        {isAnimated ? (
          <>
            <Image src={logo} alt="logo" width={144} height={36} className={styles.image} />
            <br />
          </>
        ) : (
          <Logotype
            width={
              width
                ? width
                : currentSize < widthBreakpoints.mobile_xl
                ? '96px'
                : currentSize < widthBreakpoints.desktop_s
                ? '116px'
                : '144px'
            }
            height={
              height
                ? height
                : currentSize < widthBreakpoints.mobile_xl
                ? '24.65px'
                : currentSize < widthBreakpoints.desktop_s
                ? '30px'
                : '38px'
            }
          />
        )}
      </Link>
    </div>
  );
};

export default Logo;
