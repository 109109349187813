import { postRequest } from '@/src/api/index';
import { DataResponse } from '@/src/domain/common/DataResponse';
import toErrorWithMessage from '@/src/utils/toErrorWithMessage';

import { LOGOUT_ENDPOINT } from './../../api/endpoints/server/index';

const postLogout = async (): Promise<DataResponse<unknown>> => {
  try {
    const response = await postRequest({ url: LOGOUT_ENDPOINT });
    return { ok: true, data: response };
  } catch (e) {
    const message = toErrorWithMessage(e);
    return { ok: false, data: undefined, message };
  }
};

export default postLogout;
