export const API_URL = process.env.NEXT_PUBLIC_API_URL;

// news
export const NEWS_ARTICLES_ENDPOINT = `${API_URL}/articles`;
export const MAIN_NOTIFICATION_ENDPOINT = `${API_URL}/articles/main`;
export const NEWS_ARTICLE_ENDPOINT = `${API_URL}/articles/:slug`;
export const NEWS_CATEGORY_ENDPOINT = `${API_URL}/article-categories`;
export const SUBSCRIBE_TO_UPDATES_ENDPOINT = `${API_URL}/email/subscription`;

// career page
export const VACANCIES_ENDPOINT = `${API_URL}/vacancies`;
export const VACANCY_ENDPOINT = `${API_URL}/vacancies/:slug`;
export const FAQ_ENDPOINT = `${API_URL}/faq`;

// partners
export const PARTNERS_CATEGORIES_ENDPOINT = `${API_URL}/partner-categories`;
export const PARTNERS_ENDPOINT = `${API_URL}/partners`;
export const PARTNERS_FOOTER_ENDPOINT = `${API_URL}/partners/footer`;
export const PARTNERS_FORM_ENDPOINT = `${PARTNERS_ENDPOINT}/application`;

// certificates
export const CERTIFICATES_FOOTER_ENDPOINT = `${API_URL}/certificates`;

// contact
export const CONTACT_FORM_ENDPOINT = `${API_URL}/feedback`;
export const CONTACT_SUBJECTS_ENDPOINT = `${CONTACT_FORM_ENDPOINT}/subjects`;

//meeting-requests
export const MEETING_REQUESTS_ENDPOINT = `${API_URL}/meeting-requests`;

// auth
export const AUTH_ENDPOINT = `${API_URL}/auth`;
export const REGISTER_ENDPOINT = `${API_URL}/register`;
export const LOGIN_ENDPOINT = `${API_URL}/login`;
export const CONFIRM_EMAIL_ENDPOINT = `${AUTH_ENDPOINT}/email/confirm/:token`;
export const FORGOT_PASSWORD_ENDPOINT = `${API_URL}/forgot-password`;
export const RESET_PASSWORD_ENDPOINT = `${API_URL}/reset-password`;

// client
export const PROFILE_ENDPOINT = `${API_URL}/client/profile`;
export const LOGOUT_ENDPOINT = `${API_URL}/client/logout`;
export const ROADMAP_ENDPOINT = `${API_URL}/roadmap`;

// games
export const GAMES_ENDPOINT = `${API_URL}/games`;
export const GAMES_SLIDER_ENDPOINT = `${API_URL}/slider`;
export const GAMES_SLIDER_MAIN_ENDPOINT = `${API_URL}/slider/main`;
export const GAMES_CATEGORIES_ENDPOINT = `${API_URL}/game-categories`;
export const GAMES_ORGANIZING_ENDPOINT = `${API_URL}/organizing`;
export const GAMES_BANNERS = `${API_URL}/banners`;
export const GAME_ENDPOINT = `${GAMES_ENDPOINT}/:slug`;

// slot machines
export const SLOT_MACHINES_ENDPOINT = `${API_URL}/slot-machines`;
export const SLOT_MACHINE_ENDPOINT = `${API_URL}/slot-machines/:slug`;

//about company
export const OUR_TEAM_ENDPOINT = `${API_URL}/our-team`;

//main page
export const MAIN_PAGE_GAMES_ENDPOINT = `${API_URL}/selections`;
export const MAIN_PAGE_CARDS_ENDPOINT = `${API_URL}/cards`;

//search
export const SEARCH_COMMON_ENDPOINT = `${API_URL}/search`;
export const SEARCH_GAMES_ENDPOINT = `${API_URL}/search/games`;
export const SEARCH_ARTICLES_ENDPOINT = `${API_URL}/search/articles`;

//email
export const EMAIL_RESEND_ENDPOINT = `${API_URL}/email/resend`;
