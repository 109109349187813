import Image from 'next/image';

import { GAME_APP_ENDPOINT } from '@/src/api/endpoints/app';
import { Typography } from '@/src/components/Typography';
import { GameType } from '@/src/domain/games/game/GameType';
import { Link } from '@/src/i18n/routing';

import styles from './SearchResultGameCard.module.scss';

const SearchResultGameCard = ({
  title,
  slug,
  image,
  toggleSearch,
}: GameType & { toggleSearch: () => void }) => {
  return (
    <Link
      href={GAME_APP_ENDPOINT.replace('[slug]', slug || '')}
      className={styles.search_result_game_card}
      onClick={toggleSearch}
    >
      {image && (
        <Image
          className={styles.search_result_game_card__image}
          src={image.desktop.webp_x1}
          alt={title}
          width={340}
          height={340}
        />
      )}
      <Typography.Title level={4}>{title}</Typography.Title>
    </Link>
  );
};

export default SearchResultGameCard;
