import Image from 'next/image';

import { NEWS_ARTICLE_PAGE_APP_ENDPOINT } from '@/src/api/endpoints/app';
import { Typography } from '@/src/components/Typography';
import { NewsArticleType } from '@/src/domain/news/NewsArticleType';
import { Link } from '@/src/i18n/routing';

import styles from './SearchResultArticleCard.module.scss';

const SearchResultArticleCard = ({
  title,
  slug,
  preview,
  descr,
  toggleSearch,
}: NewsArticleType & { toggleSearch: () => void }) => {
  return (
    <Link
      href={NEWS_ARTICLE_PAGE_APP_ENDPOINT.replace('[slug]', slug)}
      className={styles.search_result_article_card}
      onClick={toggleSearch}
    >
      {preview ? (
        <div className={styles.search_result_article_card__image}>
          <Image src={preview.desktop.webp_x1} alt={title} width={271} height={135.5} />
        </div>
      ) : (
        <></>
      )}
      <Typography.Title level={4}>{title}</Typography.Title>
      <p className={styles.search_result_article_card__descr}>{descr}</p>
    </Link>
  );
};

export default SearchResultArticleCard;
