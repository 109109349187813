import { PROFILE_APP_ENDPOINT } from '@/src/api/endpoints/app';
import TabsList from '@/src/components/TabsList';

export enum ProfileTabsTypesEnum {
  GAMES = 'games',
  PROMOTIONS = 'promotions',
  ROADMAP = 'roadmap',
  SUPPORT = 'support',
  SLA = 'sla',
}

interface Props {
  className?: string;
  slug?: string;
}

const ProfileTabsList = async ({ className, slug }: Props) => {
  const profileTabs = [
    {
      id: ProfileTabsTypesEnum.GAMES,
      title: 'My games',
    },
    // {
    //   id: ProfileTabsTypesEnum.PROMOTIONS,
    //   title: 'Promotion packs',
    // },
    {
      id: ProfileTabsTypesEnum.ROADMAP,
      title: 'Roadmap',
    },
  ];

  return (
    <TabsList
      data={profileTabs}
      endpoint={PROFILE_APP_ENDPOINT}
      className={className}
      defaultEndpoint={`${PROFILE_APP_ENDPOINT}/${ProfileTabsTypesEnum.GAMES}`}
      activeTabId={slug}
    />
  );
};

export default ProfileTabsList;
