import { SkypeSolid, Mail } from '@/assets/Icons';
import { PROFILE_APP_ENDPOINT } from '@/src/api/endpoints/app';

export const HOST = process.env.NEXT_PUBLIC_HOST || 'https://belatra.games';
export const AUTH_TOKEN = 'auth-token';
export const YEARS_OLD_18_CONFIRMED = 'years_old_18_confirmed';
export const YEARS_OLD_18_DENIED_LINK = 'https://www.google.com';
export const IS_USER_VERIFIED = 'is-user-verified';
export const COMPANY_NAME = 'Belatra';
export const DEFAULT_META_TITLE = 'Belatra Games - Games and Slots Provider for Online Casinos #1';
export const DEFAULT_META_DESCRIPTION =
  "An award-winning Online Game Slot Provider, we're here to transform your online and offline gaming experience with our exceptional slots. Indulge in fun, fair, and secure gaming with 80+ reeled games. Play on any device, using fiat or crypto.";

export const routesWhereNotShown18yearsOldConfirmedModal = [PROFILE_APP_ENDPOINT];

export const aboutPageSupportLinks = [
  {
    icon: <Mail />,
    label: 'marketing@belatra.com',
    link: 'mailto:marketing@belatra.com',
  },
  {
    icon: <SkypeSolid />,
    label: 'belatra_marketing',
    link: 'skype:belatra_marketing?chat',
  },
];
