import queryString from 'query-string';

const objectToQueryString = (queryObject: Record<string, unknown>): string =>
  queryString.stringify(queryObject, {
    arrayFormat: 'bracket',
    skipEmptyString: true,
    skipNull: true,
  });

export default objectToQueryString;
