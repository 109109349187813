import { toast } from 'react-toastify';

import handleError from './handleError';

export type ErrorWithMessage = {
  message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
}

function toErrorWithMessage(maybeError: unknown, withoutToast?: boolean) {
  if (isErrorWithMessage(maybeError)) {
    const message = JSON.parse(maybeError.message)?.message ?? JSON.parse(maybeError.message);
    !withoutToast && toast.error(message);
    console.error(message);
    return message;
  }
  const error = JSON.stringify(maybeError);
  handleError(error);
  return error;
}

export default toErrorWithMessage;
