import { mapJsonToNewsArticle } from '@/src/data/news/mapJsonToNewsArticle';
import { SearchResultType } from '@/src/domain/search/SearchResultType';

import { SearchResultJsonType } from './SearchResultJsonType';

import mapJsonToGame from '../games/mapJsonToGame';

const mapJsonToSearchResult = (json: SearchResultJsonType): SearchResultType => ({
  articles: json.articles.map(mapJsonToNewsArticle),
  games: json.games.map(mapJsonToGame),
});

export default mapJsonToSearchResult;
