import cn from 'classnames';

import styles from './Checkbox.module.scss';

interface Props {
  callback: () => void;
  size?: 's' | 'm';
  bordered?: boolean;
}

const Checkbox = ({ callback, size = 's', bordered }: Props) => {
  return (
    <label
      className={cn(styles.checkbox_label, [styles[`checkbox_label--${size}`]], {
        [styles['checkbox_label--bordered']]: bordered,
      })}
    >
      <input type="checkbox" className={styles.checkbox_input} onChange={callback} />
      <span className={styles.checkbox_custom} />
    </label>
  );
};

export default Checkbox;
