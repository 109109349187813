'use client';
import { useState } from 'react';

import cn from 'classnames';
import { useLocale, useTranslations } from 'next-intl';
import { toast } from 'react-toastify';

import Button from '@/src/components/Button';
import Checkbox from '@/src/components/Checkbox';
import { Typography } from '@/src/components/Typography';
import postSubscribeToUpdates from '@/src/services/news/postSubscribeToUpdates';

import styles from './SubscribeToUpdatesBlock.module.scss';

const SubscribeToUpdatesBlock = ({ isSmall }: { isSmall?: boolean }) => {
  const locale = useLocale();
  const t = useTranslations();

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [accepted, setIsChecked] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    await postSubscribeToUpdates({ locale, email })
      .then(() => {
        toast.success(t('toasts.successSubscribe', { email }));
        setEmail('');
      })
      .catch(err => {
        toast.error(err.message || t('toasts.errorSubmit'));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleCheckbox = () => {
    setIsChecked(prev => !prev);
  };

  return (
    <div className={cn(styles.block, { [styles['block--small']]: isSmall })}>
      {isSmall ? (
        <p className={styles.title_small}>{t('news_page.get_latest_update')}</p>
      ) : (
        <Typography.Title level={2} className={styles.title}>
          {t('news_page.get_latest_update')}
        </Typography.Title>
      )}
      <div className={styles.block__actions}>
        <input
          type="email"
          value={email}
          onChange={({ currentTarget }) => setEmail(currentTarget.value)}
          placeholder={t('placeholders.enter_email')}
          className={styles.input}
        />
        <Button
          text={t('buttons.subscribe')}
          className={cn(styles.button, { [styles['button--small']]: isSmall })}
          onClick={onSubmit}
          loading={isLoading}
          disabled={!accepted}
        />
      </div>
      <div className={styles.block__privacy}>
        <Checkbox callback={toggleCheckbox} size="m" />
        <Typography.Paragraph
          level={2}
          weight={400}
          className={cn(styles.block__privacy__descr, {
            [styles['block__privacy__descr--small']]: isSmall,
          })}
        >
          I have read the
          <a href="/docs/privacy-policy.pdf" target="_blank">
            Privacy Policy
          </a>
          and agree to the processing of my personal data
        </Typography.Paragraph>
      </div>
    </div>
  );
};

export default SubscribeToUpdatesBlock;
