import cn from 'classnames';

import styles from './Spinner.module.scss';

interface Props {
  color?: 'white' | 'blue';
  position?: 'static' | 'absolute';
  size?: number;
}

const Spinner = ({ color, position = 'static', size = 20 }: Props) => {
  const colorValue = color === 'blue' ? '#0041ff' : '#FFF';

  return (
    <span
      className={cn(styles.loader, { [styles['loader--absolute']]: position === 'absolute' })}
      style={{ borderColor: `${colorValue} transparent`, height: size, width: size }}
    />
  );
};

export default Spinner;
