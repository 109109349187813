import { NewsArticleType } from '@/src/domain/news/NewsArticleType';

import { NewsArticleJson } from './NewsArticleJson';

export const mapJsonToNewsArticle = (json: NewsArticleJson): NewsArticleType => ({
  id: json.id,
  slug: json.slug,
  descr: json.short_content,
  content: json?.content ?? '',
  title: json.title,
  date: json.date,
  category: json.category,
  preview: json.image,
  is_show_booking_form: json.is_show_booking_form,
  subtitle: json.subtitle || null,
  gameLink: json.link_to_game || null,
  buttonText: json.button_text || null,
  metaTitle: json.meta_title ?? json.title,
  metaDescription: json.meta_title ?? json.title,
});
