'use client';
import { useEffect, useState } from 'react';

import cn from 'classnames';
import { deleteCookie } from 'cookies-next';
import { nanoid as uuid } from 'nanoid';
import { useTranslations } from 'next-intl';
import { usePathname, useRouter } from 'next/navigation';

import { SearchIcon } from '@/assets/Icons';
import {
  LOGIN_APP_ENDPOINT,
  PROFILE_APP_ENDPOINT,
  REGISTRATION_APP_ENDPOINT,
  RESTORE_PASSWORD_APP_ENDPOINT,
} from '@/src/api/endpoints/app';
import { navigation } from '@/src/api/navigation';
import { AUTH_TOKEN } from '@/src/constants';
import { NewsArticlesCategoryType } from '@/src/domain/news/NewsArticleCategoryType';
import Container from '@/src/hocs/Container';
import { Link } from '@/src/i18n/routing';
import postLogout from '@/src/services/client/postLogout';

import BurgerMenu from './components/BurgerMenu';
import Logo from './components/Logo';
import DropList from './components/NavDroplist';
import NavItem from './components/NavItem';

import { ProfileTabsTypesEnum } from '../ProfilePage/components/ProfileTabsList';
import Search from '../Search';

import styles from './Header.module.scss';

interface Props {
  isAuth: boolean;
  articleCategories: NewsArticlesCategoryType[];
}

const Header = ({ isAuth, articleCategories }: Props) => {
  const t = useTranslations();
  const router = useRouter();
  const path = usePathname() ?? '';
  const [searchHide, setSearchHide] = useState(true);
  const [isMenuActive, setIsMenuActive] = useState<boolean>(false);
  const toggleSearch = () => setSearchHide(prev => !prev);
  const toggleMenu = () => setIsMenuActive(prev => !prev);

  const isAccountLinkActive =
    path.includes(PROFILE_APP_ENDPOINT) ||
    path.includes(LOGIN_APP_ENDPOINT) ||
    path.includes(RESTORE_PASSWORD_APP_ENDPOINT) ||
    path.includes(REGISTRATION_APP_ENDPOINT);

  useEffect(() => {
    if (searchHide && !isMenuActive) {
      document.body.style.overflow = 'initial';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [searchHide, isMenuActive]);

  const logout = () =>
    postLogout().then(() => {
      deleteCookie(AUTH_TOKEN);
      router.push(LOGIN_APP_ENDPOINT);
    });

  const clientAreaDropdownItem = () => [
    {
      href: LOGIN_APP_ENDPOINT,
      title: 'logout',
      onClick: isAuth ? logout : undefined,
    },
  ];

  return (
    <>
      <header className={cn(styles.header)}>
        <Container className={styles.header__container}>
          <Logo isAnimated />
          <ul className={styles.nav}>
            {navigation.map(ni =>
              ni.mobile ? null : <NavItem key={uuid()} {...ni} path={path} />,
            )}
          </ul>

          <div className={styles.actions}>
            {/* <ToggleLocale /> */}
            <div className={styles.area__wrapper}>
              <Link
                href={`${PROFILE_APP_ENDPOINT}/${ProfileTabsTypesEnum.GAMES}`}
                className={cn(styles.area, { [styles.area__active]: isAccountLinkActive })}
              >
                {t('navigation.client_area')}
              </Link>
              {isAuth ? <DropList path={path} items={clientAreaDropdownItem()} /> : <></>}
            </div>

            <div className={styles.button_search} onClick={toggleSearch}>
              <SearchIcon />
            </div>
          </div>

          <div className={styles.burger} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Container>
      </header>
      <Search
        toggleSearch={toggleSearch}
        searchHide={searchHide}
        articleCategories={articleCategories}
      />
      <BurgerMenu
        isActive={isMenuActive}
        toggleMenu={toggleMenu}
        toggleSearch={toggleSearch}
        logout={logout}
        isAuth={isAuth}
      />
    </>
  );
};

export default Header;
