'use client';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { debounce } from 'lodash';
import { useTranslations } from 'next-intl';

import { Close } from '@/assets/Icons';
import { NewsArticlesCategoryType } from '@/src/domain/news/NewsArticleCategoryType';
import { SearchResultType } from '@/src/domain/search/SearchResultType';
import Container from '@/src/hocs/Container';
import getSearch, { DefaultSearchCategory } from '@/src/services/search/getSearch';

import SearchResultArticleCard from './components/SearchResultArticleCard';
import SearchResultGameCard from './components/SearchResultGameCard';

import Spinner from '../Spinner';
import TabsButtonList from '../TabsButtonList';
import { Typography } from '../Typography';

import styles from './Search.module.scss';

interface Props {
  toggleSearch: () => void;
  searchHide: boolean;
  articleCategories: NewsArticlesCategoryType[];
}

const Search = ({ toggleSearch, searchHide, articleCategories }: Props) => {
  const t = useTranslations();
  const [searchedItems, setSearchedItems] = useState<SearchResultType>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [artiveSearchTabId, setActiveSearchTabId] = useState<string>(DefaultSearchCategory.ALL);
  const [isDataFetching, setIsDataFetching] = useState(false);

  const tabs = useMemo(() => {
    return [
      ...Object.values(DefaultSearchCategory).map(id => ({ title: t(`search.${id}`), id })),
      ...(articleCategories || []),
    ];
  }, [articleCategories]);

  const isNothingFound =
    searchValue &&
    (!searchedItems || (!searchedItems?.articles.length && !searchedItems?.games.length));

  const search = (value: string) => {
    if (value.length) {
      setIsDataFetching(true);
      getSearch({ value, category: artiveSearchTabId })
        .then(res => {
          setSearchedItems(res.data);
          setSearchValue(value);
        })
        .finally(() => {
          setIsDataFetching(false);
        });
    }
  };

  const debouncedSearch = debounce(search, 500);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => debouncedSearch(e.target.value);

  const handleClick = () => debouncedSearch(searchValue);

  const changeActiveSearchTabId = (id: string) => {
    setActiveSearchTabId(id);
  };

  useEffect(() => {
    if (searchValue) {
      debouncedSearch(searchValue);
    }
  }, [artiveSearchTabId]);

  return (
    <div className={cn(styles.panel, { [styles.panel_open]: !searchHide })}>
      <div className={styles.panel_actions}>
        <div className={styles.close} onClick={toggleSearch}>
          <Close />
        </div>
      </div>

      <Container className={styles.content}>
        <div className={styles.content_actions}>
          <form onSubmit={e => e.preventDefault()}>
            <input
              className={styles.content_input}
              placeholder={t('search.placeholder')}
              type="text"
              onChange={handleChange}
            />
            <button className={styles.content_actions__button} type="button" onClick={handleClick}>
              {t('buttons.search')}
            </button>
          </form>
          <TabsButtonList
            data={tabs}
            onChange={changeActiveSearchTabId}
            activeTabId={artiveSearchTabId}
          />
        </div>
        <div className={styles.content_result}>
          {isNothingFound ? (
            <div className={styles.content_nothing_found}>
              <Typography.Title className={styles.content_nothing_found__title} level={4}>
                {t('search.nothing_found.title')}&nbsp;
                <span>{searchValue}</span>
              </Typography.Title>
              <p className={styles.content_nothing_found__descr}>
                {t('search.nothing_found.title')}
              </p>
            </div>
          ) : isDataFetching ? (
            <Spinner position="absolute" color="blue" size={50} />
          ) : (
            <>
              {searchedItems?.games.map(card => (
                <SearchResultGameCard {...card} toggleSearch={toggleSearch} key={card.id} />
              ))}
              {searchedItems?.articles.map(card => (
                <SearchResultArticleCard {...card} toggleSearch={toggleSearch} key={card.id} />
              ))}
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Search;
