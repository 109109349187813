//news

export const MAIN_PAGE_APP_ENDPOINT = '/';
export const NEWS_PAGE_APP_ENDPOINT = '/news';
export const NEWS_CATEGORY_PAGE_APP_ENDPOINT = '/news/category';
export const NEWS_ARTICLE_PAGE_APP_ENDPOINT = '/news/article/[slug]';
export const PARTNERS_APP_ENDPOINT = '/partners';
export const CONTACT_APP_ENDPOINT = '/contact';
export const REGISTRATION_APP_ENDPOINT = '/registration';
export const LOGIN_APP_ENDPOINT = '/login';
export const RESTORE_PASSWORD_APP_ENDPOINT = '/restore';
export const PROFILE_APP_ENDPOINT = '/profile';
export const GAMES_APP_ENDPOINT = '/games';
export const GAME_APP_ENDPOINT = '/games/game/[slug]';
export const GAMES_CATEGORY_APP_ENDPOINT = '/games/category/[slug]';
export const CAREER_APP_ENDPOINT = '/career';
export const VACANCY_APP_ENDPOINT = '/career/[slug]';
export const ABOUT_COMPANY_APP_ENDPOINT = '/about';
export const SLOT_MACHINES_APP_ENDPOINT = '/slot-machines';
export const SLOT_MACHINES_COMPARE_APP_ENDPOINT = '/slot-machines/compare';
export const SLOT_MACHINES_SLUG_APP_ENDPOINT = '/slot-machines/[slug]';

export const ABOUT_EMPLOYEE_APP_ENDPOINT = '/about/[employeeId]';
