import { useState } from 'react';

import cn from 'classnames';
import { useTranslations } from 'next-intl';

import { Link } from '@/src/i18n/routing';

import DropList from '../NavDroplist';

import styles from './NavItem.module.scss';

export interface NavItemType {
  href?: string;
  title: string;
  childs?: { href?: string; title: string }[];
}

const NavItem = ({ href, title, childs, path }: NavItemType & { path: string }) => {
  const t = useTranslations('navigation');
  const routeName = path.split('/')[1];
  // const isLinkActive = href && href !== '/' ? path.includes(href) : false;
  const isLinkActive = href && href !== '/' && path !== '/' ? href.includes(routeName) : false;

  const [isChildActive, setIsChildActive] = useState<boolean>(false);

  const setActive = (boolean: boolean) => {
    setIsChildActive(boolean);
  };

  return href ? (
    <li>
      <Link
        key={title}
        href={href}
        className={cn(styles.nav_item, {
          [styles.nav_item__active]: isLinkActive || isChildActive,
        })}
      >
        {t(title)}
        {childs && childs?.length > 0 && (
          <DropList path={path} items={childs} setActive={setActive} />
        )}
      </Link>
    </li>
  ) : (
    <li
      key={title}
      className={cn(styles.nav_item, { [styles.nav_item__active]: isLinkActive || isChildActive })}
    >
      {t(title)}
      {childs && childs?.length > 0 && (
        <DropList path={path} items={childs} setActive={setActive} />
      )}
    </li>
  );
};

export default NavItem;
